<template>
  <div class="auth-logged-in auth-logged-in--border-wrapper">
    <div class="grey-layer">
      <!--
        -- User card
        -->
      <div class="user-card">
        <img
          class="user-card__avatar"
          :src="avatar"
        >

        <p class="user-card__name title-3">
          {{ fullName }}
        </p>

        <!-- <p class="text-1">
            @<span>{{ telegramUserName }}</span>
            #<span>{{ userId }}</span>
          </p> -->
      </div>

      <!--
        -- Balance
        -->
      <label>BALANCE</label>
      <h1 class="title-1">
        $ 1.57m
      </h1>
    </div>

    <!--
      -- Settings
      -->
    <div class="white-layer white-layer--group">
      <h3 class="title-3">
        Settings
      </h3>

      <fieldset>
        <label>Binance API key</label>
        <ui-input placeholder="asdasda">
          Label
        </ui-input>
      </fieldset>

      <fieldset>
        <label>Binance API Secret</label>
        <ui-input placeholder="asdasda">
          Label
        </ui-input>
      </fieldset>

      <ui-button
        class="mt-2"
        :type="1"
        @click="saveKeys"
      >
        Save keys
      </ui-button>
    </div>

    <ui-button
      :type="2"
      @click="authLogout"
    >
      Logout
    </ui-button>
  </div>
</template>

<script>
import api from '@/terminal/api'
import { mapState, mapStores, mapActions } from 'pinia'
import { useUserStore } from '@/terminal/store/user'

export default {
  components: {

  },

  computed: {
    ...mapStores(useUserStore),
    ...mapState(useUserStore, [
      'userId',
      'fullName',
      'telegramUserName',
      'avatar',
    ]),
  },

  methods: {
    ...mapActions(useUserStore, [
      'loginCheck',
      'telegramLogin',
      'logout',
    ]),

    async authLogout() {
      this.logout()
    },

    async getAll() {
      const prices = await api.getPrices()

      console.log('prices', prices)

      const orders = await api.getOrders('BNBUSDT')

      console.log('orders', orders)

      const balances = await api.getBalances()

      console.log('balances', balances)

      const tickers = await api.getTickers()

      console.log('tickers', tickers)
    },

    async saveKeys() {

    },
  },
}
</script>

<style lang="stylus">
@import '../../styles/global.styl'

.auth-logged-in
  display flex
  flex-direction column
  width 100%
  box-sizing border-box
  min-height 100%

.white-layer
  padding 22px 0 16px 0
  // background var(--c-backgrounds-primary)
  // border-radius 24px 24px 0 0
  flex 1
  display flex
  flex-direction column
  gap 16px

  &--group
    border-radius 24px
    margin-bottom 12px

.user-card
  display flex
  align-items center
  padding-bottom 16px
  gap 16px

  p
    margin-bottom 0

  &__avatar
    width 36px
    height 36px
    border-radius 100px
    background var(--c-fills-primary)
    overflow hidden

  //&__name

</style>
