<template>
  <div class="auth">
    <!--
      -- Anon screen
      -->
    <auth-anon v-if="!isLoggedIn" />

    <!--
      -- Logged in screen
      -->
    <auth-logged-in v-else />
  </div>
</template>

<script>
import api from '@/terminal/api'
import { mapState, mapStores, mapActions } from 'pinia'
import { useUserStore } from '@/terminal/store/user'
import AuthAnon from './AuthAnon.vue'
import AuthLoggedIn from './AuthLoggedIn.vue'

export default {
  components: {
    AuthAnon,
    AuthLoggedIn,
  },

  computed: {
    ...mapStores(useUserStore),
    ...mapState(useUserStore, [
      'userId',
      'fullName',
      'telegramUserName',
      'isLoggedIn',
      'avatar',
    ]),
  },

  methods: {
    ...mapActions(useUserStore, [
      'loginCheck',
      'logout',
    ]),

    async getAll() {
      const prices = await api.getPrices()

      console.log('prices', prices)

      const orders = await api.getOrders('BNBUSDT')

      console.log('orders', orders)

      const balances = await api.getBalances()

      console.log('balances', balances)

      const tickers = await api.getTickers()

      console.log('tickers', tickers)
    },
  },
}
</script>

<style lang="stylus">
@import '../../styles/global.styl'

.auth
  height 100%
  display flex
  justify-content center
  overflow-y auto
  padding-top 24px

</style>
