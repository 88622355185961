<template>
  <div class="auth-anon-wrapper">
    <div class="auth-anon auth-anon--border-wrapper">
      <!-- <img
        class="auth-anon__logo"
        src="/img/icons/icon-192x192.png"
      > -->

      <h1 class="auth-anon__title title-2">
        Next-gen trading
      </h1>

      <p class="auth-anon__sub-title text-1">
        Connect with your telegram account and get awesome bot notifications
      </p>

      <div class="auth-anon__telegram-widget">
        <vue-telegram-login
          mode="callback"
          telegram-login="aitt_bot"
          request-access="write"
          size="large"
          @callback="telegramLoginCallback"
        />
      </div>

      <div
        v-if="$dev"
        class="auth-anon__dev-buttons"
      >
        <ui-button
          :type="2"
          size="small"
          @click="testLogin"
        >
          Login
        </ui-button>

        <ui-button
          :type="2"
          size="small"
          @click="createTestUser"
        >
          Create user
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import { vueTelegramLogin } from 'vue-telegram-login'
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '@/terminal/store/user'
import api from '@/terminal/api'

export default {
  components: {
    vueTelegramLogin,
  },

  computed: {
    ...mapState(useUserStore, [
      'isLoggedIn',
    ]),
  },

  async mounted() {
    // this.testLogin()
  },

  methods: {
    ...mapActions(useUserStore, [
      'telegramLogin',
    ]),

    /**
     * Telegram login callback
     */
    async telegramLoginCallback(user) {
      try {
        await this.telegramLogin(user)
      } catch (err) {
        console.error('Auth error', err)
      }
    },

    async createTestUser() {
      await api.createTestUser()
    },

    async testLogin() {
      const user = JSON.parse('{"id":167489523,"first_name":"Мика","username":"nalbandyan","photo_url":"https://t.me/i/userpic/320/zNSBXoc3aCnEJ2szpl1uY8vxYc2Pnbf_TDqucHfo0WQ.jpg","auth_date":1654730846,"hash":"fb7e0e8c7ab93d1cb6803684b9a46f2270b94d0177673b6dcbfbaff24b48f0d4"}')

      await this.telegramLogin(user)
    },

  },
}
</script>

<style lang="stylus">
@import '../../styles/global.styl'

  .auth-anon-wrapper
    display flex
    align-items center

  .auth-anon
    display flex
    flex-direction column
    align-items center
    text-align center

    &__title
      margin 0px 0 14px 0

    &__sub-title
      max-width 270px

    &__logo
      width 88px
      height 88px
      border-radius 20px

    &__telegram-widget
      height 47px
      margin-top 10px

    &__dev-buttons
      display flex
      gap 12px
      margin-top 20px

</style>
